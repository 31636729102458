import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

const Servicios = ({ sectionClass, background }) => {
  const [state] = useState({
    services1: [
      {
        icon: "pe-7s-browser",
        title: "Desarrollo de Software",
        desc: "Convierte tus ideas en aplicaciones robustas y eficientes con nuestro equipo de expertos en desarrollo de software. Nos encargamos de todo el proceso, desde el diseño inicial hasta la programación y pruebas exhaustivas. Garantizamos soluciones a medida que cumplen con los más altos estándares de calidad.",
      },
      {
        icon: "pe-7s-server",
        title: "Bases de Datos",
        desc: "Creamos bases de datos que potencian tu negocio. Diseñamos, implementamos y gestionamos sistemas de almacenamiento eficientes, asegurando un acceso rápido y seguro a tu información. Deja que nuestros expertos optimicen tus datos para un rendimiento superior. ¡Impulsa tu negocio con nosotros!",
      },
      {
        icon: "pe-7s-phone",
        title: "Desarrollo de Apps",
        desc: "Creamos aplicaciones móviles que destacan. Diseñamos y desarrollamos software para smartphones y tablets, brindando soluciones innovadoras y funcionales que cautivan a tus usuarios. ¡Haz que tu idea cobre vida en la palma de tu mano!.",
      },
    ],
    services2: [
      {
        icon: "pe-7s-display1",
        title: "Marketing Digital",
        desc: "Conectamos tu marca con el mundo digital. Aplicamos estrategias de marketing adaptadas a los medios online para promover tus productos o servicios. Alcanzamos a tu audiencia específica y potenciamos tu presencia en el mercado digital. ¡Haz crecer tu negocio con nosotros!",
      },
      {
        icon: "pe-7s-cloud",
        title: "Administración en Infraestructuras Cloud",
        desc: "Gestionamos tus recursos en la nube para garantizar disponibilidad y seguridad. Utilizamos tecnologías como Google Cloud Platform para optimizar y proteger tu infraestructura. Confía en nuestros expertos para mantener tu negocio siempre en funcionamiento.",
      },
      {
        icon: "pe-7s-credit",
        title: "Desarrollo  Medios de Pago POS",
        desc: "Creamos aplicaciones y sistemas POS personalizados para tu negocio. Facilita transacciones seguras y eficientes con nuestras soluciones adaptadas a tus necesidades. ¡Transforma la forma en que realizas ventas!",
      },
    ],
  });

  useEffect(() => {
    AOS.init({
      duration: 700,
    });
  }, []);

  return (
    <React.Fragment>
      <section className={`section ${sectionClass} ${background === "gris" ? "bg-light" : ""}`} id="servicio">
        <Container className="pt-2 pb-2">
          <SectionTitle
            title="Nuestros Servicios"
            desc="Con una sólida trayectoria en tecnología y un enfoque innovador en el desarrollo de software, ofrecemos soluciones digitales que transforman y potencian tu negocio. Nos dedicamos a crear experiencias de marca excepcionales y a generar un impacto positivo y tangible en la sociedad. Descubre cómo podemos ayudarte a alcanzar tus objetivos con nuestras soluciones a medida."
          />
          <Row className="mt-4" data-aos="fade-up">
            <ServiceBox services={state.services1} />
          </Row>
          <Row className="mt-4" data-aos="fade-up" data-aos-delay="200">
            <ServiceBox services={state.services2} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Servicios;