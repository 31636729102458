import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { AiFillFacebook } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { SiYoutube } from "react-icons/si";
import AOS from "aos";
import "aos/dist/aos.css";

const Social = ({ background }) => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <React.Fragment>
      <section
        className={
          "contact-social " + (background === "gris" ? "bg-light" : "")
        }
      >
        <Container className="text-center">
          <Row className="align-items-center">
            {/* Columna para la dirección de correo */}
            <Col lg={3} className="mt-4" data-aos="fade-right">
              <p className="contact-title" style={{ textAlign: "center", fontSize: "20px"}}>
                <a
                  href="mailto:contacto@ione.cl"
                  className="contact-link"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <span
                    onMouseOver={(e) => (e.target.style.color = "green")}
                    onMouseOut={(e) => (e.target.style.color = "black")}
                  >
                    {/* contacto@ione.cl */}
                  </span>
                </a>
              </p>
            </Col>
            {/* Columna para los iconos de redes sociales */}
            <Col lg={6} className="mt-4">
              <ul className="list-inline" style={{ justifyContent: "center" }}>
                <li
                  className="list-inline-item me-3"
                  data-aos="fade-right"
                  data-aos-delay="100"
                >
                  <a
                    href="https://www.facebook.com/ionetechchile"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center"
                  >
                    <AiFillFacebook
                      style={{ color: "blue", fontSize: "22px" }}
                    />
                    <span
                      style={{ color: "black", marginLeft: "5px" }}
                      onMouseOver={(e) => (e.target.style.color = "blue")}
                      onMouseOut={(e) => (e.target.style.color = "black")}
                    >
                      ionetechchile
                    </span>
                  </a>
                </li>
                <li
                  className="list-inline-item me-3"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <a
                    href="https://www.linkedin.com/company/ionetech"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center"
                  >
                    <FaLinkedinIn style={{ color: "blue", fontSize: "22px" }} />
                    <span
                      style={{ color: "black", marginLeft: "5px" }}
                      onMouseOver={(e) => (e.target.style.color = "blue")}
                      onMouseOut={(e) => (e.target.style.color = "black")}
                    >
                      ionetech
                    </span>
                  </a>
                </li>
                <li
                  className="list-inline-item"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <a
                    href="https://www.youtube.com/@ionetechcl"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center"
                  >
                    <SiYoutube style={{ color: "red", fontSize: "22px" }} />
                    <span
                      style={{ color: "black", marginLeft: "5px" }}
                      onMouseOver={(e) => (e.target.style.color = "red")}
                      onMouseOut={(e) => (e.target.style.color = "black")}
                    >
                      @ionetechcl
                    </span>
                  </a>
                </li>
              </ul>
            </Col>
            {/* Columna para el número de teléfono */}
            <Col
              lg={3}
              className="mt-4"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <p className="contact-title" style={{ textAlign: "center", fontSize: "18px" }}>
                <a
                  href="tel:+56947520736"
                  className="contact-link"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <span
                    onMouseOver={(e) => (e.target.style.color = "green")}
                    onMouseOut={(e) => (e.target.style.color = "black")}
                  >
                    {/* +56 9 4752 0736 */}
                  </span>
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Social;
