import React, { useState } from "react";
import { Col } from "reactstrap";

const ServiceBox = ({ services }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
  };

  return (
    <React.Fragment>
      {services.map((service, key) => (
        <Col
          lg={4}
          key={key}
          className="mt-3"
          onMouseEnter={() => setHoveredIndex(key)}
          onMouseLeave={() => setHoveredIndex(null)}
          style={{ marginBottom: "5px", padding: "0 5px" }}
        >
          <div
            className="services-box text-center hover-effect"
            style={{ padding: "10px" }}
          >
            <i
              className={service.icon + " text-primary"}
              style={hoveredIndex === key ? styles.iconHovered : styles.icon}
            ></i>
            <h4
              className="pt-2"
              style={hoveredIndex === key ? styles.titleHovered : styles.title}
            >
              {service.title}
            </h4>
            <p className="pt-2 text-black">{service.desc}</p>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default ServiceBox;
