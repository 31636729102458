import React, {} from "react";
import { Container } from "reactstrap";
import "aos/dist/aos.css";
import SectionTitle from "../common/section-title";
import "./cliente.css";
import InfiniteCarousel from '../infinite-carousel/InfiniteCarousel'

const Clientes = ({ background }) => {
  const clientes = [
    {
      title: "",
      subtitle: "",
      image: "/assets/images/clientes/getnet.png",
    },
    {
      title: "",
      subtitle: "",
      image: "/assets/images/clientes/arduini.png",
    },
    {
      title: "",
      subtitle: "",
      image: "/assets/images/clientes/cencosud.png",
    },
    // {
    //   title: "",
    //   subtitle: "",
    //   image: "/assets/images/clientes/santander.png",
    // },
    {
      title: "",
      subtitle: "",
      image: "/assets/images/clientes/agrosuper.png",
    },
    {
      title: "",
      subtitle: "",
      image: "/assets/images/clientes/adexus.png",
    },
  ];

  return (
    <React.Fragment>
      <section
        className={"section pt-5 " + (background === "gris" ? "bg-light" : "")}
        id="clientes"
      >
        <Container>
          <SectionTitle
            title="Nuestros Clientes"
            desc="Nuestros clientes son fundamentales para nuestra empresa. Ofrecemos soluciones tecnológicas personalizadas que impulsan su éxito. Nuestra dedicación a la excelencia nos permite construir relaciones sólidas y duraderas, superando expectativas en cada proyecto."
          />
          <div className="mt-5 carousel">
          <InfiniteCarousel listado={clientes} />
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clientes;