import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const Tecnologias = ({ background }) => {
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
  }, []);

  return (
    <React.Fragment>
      <section className={"section " + (background === "gris" ? "bg-light" : "")} id="tecnologias">
        <Container>
          <Row className="vertical-content">
            <Col lg={6} data-aos="fade-right">
              <div className="features-box">
                <h3>TECNOLOGÍAS</h3>
                <p className="text-muted web-desc">
                  Somos una empresa líder en tecnología con una amplia experiencia y un compromiso inquebrantable con la excelencia técnica. Nuestro enfoque nos permite ofrecer servicios de alta calidad en diversas áreas clave, incluyendo el desarrollo de software, la administración de sistemas, la consultoría tecnológica y el soporte avanzado.
                </p>
                <ul className="text-muted list-unstyled mt-4 features-item-list">
                  <li>Desarrollo Full Stack: Creación de soluciones completas para Windows, Mobile y Web, abarcando todos los aspectos del desarrollo de software.</li>
                  <li>Lenguajes de Programación: Nuestro equipo es experto en una amplia variedad de lenguajes de programación, garantizando la mejor solución para cada proyecto.</li>
                  <li>Documentación y Requerimientos: Nos encargamos de la documentación detallada de soluciones y el levantamiento exhaustivo de requerimientos, asegurando claridad y precisión en cada etapa del desarrollo.</li>
                  <li>Mejores Prácticas: Aplicamos las mejores prácticas de desarrollo y seguridad, asegurando que cada proyecto no solo sea efectivo, sino también seguro y sostenible a largo plazo.</li>
                </ul>
              </div>
            </Col>
            <Col lg={5} style={{overflowX: 'hidden'}}>
              <div data-aos="fade-left">
                <div className="features-img features-center text-end">
                  <img
                    src="assets/images/boxlenguajes.png"
                    alt=""
                    className="img-fluid features-center text-end"
                  />
                </div>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Tecnologias;
