import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import './styles.css';

export default function NotFound() {
  let navigate = useNavigate();

  const handleVolver = () => {
    navigate('/');
  }

  return (
    <div id="not_found" className="not-found-container">
      <h1>PÁGINA NO ENCONTRADA</h1>
      <p>Esta página no existe o no se encuentra disponible</p>
      <Button color="primary" type="button" onClick={handleVolver}>
        Volver Atrás
      </Button>
    </div>
  );
}
