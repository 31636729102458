import React from "react";
import { Col } from "reactstrap";

const ProcessBox = ({ imagenes }) => {
  const urls = [
    "https://www.certificadoradelsur.cl/website/",
    "https://www.paxtechnology.com/",
    "https://aws.amazon.com/",
    "https://cloud.google.com/",
    "https://www.cloudflare.com/",
  ];

  return (
    <React.Fragment>
      <style jsx="true">{`
        .process-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transition: transform 0.3s ease;
        }
        .image-container {
          width: 130%; 
          display: flex;
          padding: 2rem;
          justify-content: center;
          align-items: center;
          transition: transform 0.3s ease;
        }
        .image-container img {
          max-width: 100%; 
          height: auto;
          object-fit: contain;
          transition: transform 0.3s ease;
        }
        .image-container:hover img {
          transform: scale(1.1);
        }
        .process-box-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px; 
        }

        @media (max-width: 576px) { /* xs: Extra Small devices */
          .process-box-wrapper {
            flex-direction: column;
          }
          .image-container {
            padding: 1rem; /* Reduce padding en xs */
          }
          .image-container img {
            max-width: 100px; /* Ajustar tamaño para XS */
          }
        }

        @media (min-width: 768px) and (max-width: 991px) { /* md: Medium devices */
          .process-box-wrapper {
            flex-direction: row;
          }
          .image-container {
            padding: 1rem; /* Ajustar padding para MD */
          }
          .image-container img {
            max-width: 100px; /* Ajustar tamaño para MD */
          }
        }
      `}
      </style>
      {imagenes.map((process, key) => (
        <Col key={key} xs={5} md={2} lg={2} className="process-box-wrapper" data-aos="fade-up" data-aos-delay={key * 100}>
          <div className="process-box">
            <div className="image-container">
              <a href={urls[key]} target="_blank" rel="noopener noreferrer">
                <img src={process.image} alt={`Partner ${key + 1}`} />
              </a>
            </div>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default ProcessBox;
