import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionTitle from "../common/section-title";
import ProcessBox from "./PartnersBox";

const Partners = ({ background }) => {
  const [state] = useState({
    imagenes: [
      {
        image: "/assets/images/partners/CDS.png",
      },
      {
        image: "/assets/images/partners/pax.png",
      },
      {
        image: "/assets/images/partners/aws.png",
      },
      {
        image: "/assets/images/partners/gcp.png",
      },
      {
        image: "/assets/images/partners/Cloudflare-Logo.wine.png",
      },
    ],
  });

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  return (
    <React.Fragment>
      <section
        className={"section " + (background === "gris" ? "bg-light" : "")}
        id="partners"
      >
        <Container>
          <SectionTitle
            title="Partners"
            desc="Nos enorgullece colaborar con líderes tecnológicos como Cloudflare, Google Cloud, Amazon Web Services, Pax, y Certificadora del sur. Estas alianzas estratégicas nos permiten ofrecer soluciones avanzadas y confiables que impulsan el éxito de nuestros clientes, aprovechando la innovación y la fiabilidad que cada uno de estos socios tecnológicos proporciona."
          />

          <Row className="mt-4 d-flex justify-content-between align-items-center" style={{overflow: 'hidden', padding: '0.5rem'}}>
            <ProcessBox imagenes={state.imagenes} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Partners;
