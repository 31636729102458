import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";
import WhatsAppButton from "../Footer/WhatsAppButton";

const NavbarPage = ({ navItems }) => {
  const [state, setState] = useState({
    isOpenMenu: false,
    navClass: "",
  });

  const toggle = () => {
    setState({ isOpenMenu: !state.isOpenMenu });
  };

  const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

  useEffect(() => {
  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      if (state.navClass !== "is-sticky") {
        setState({ navClass: "is-sticky" });
      }
    } else {
      if (state.navClass === "is-sticky") {
        setState({ navClass: "" });
      }
    }
  };

  window.addEventListener("scroll", scrollNavigation, true);

  return () => {
    window.removeEventListener("scroll", scrollNavigation, true);
  };
}, [state.navClass]);


  //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  let targetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      <style jsx="true">{`
        .nav-link {
          transition: transform 0.3s ease;
        }
        .nav-link:hover {
          transform: scale(1.1);
        }
      `}</style>
      <div id="navbar" className={state.navClass}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
          <Container>
            <WhatsAppButton href="/" />
            <NavbarBrand className="logo" onClick={scrollToTop}>
              <img
                src="/assets/images/testimonials/logoblanco.webp"
                alt="Logo de IONETECH"
                style={{ width: "150px", height: "auto" }}
              />
            </NavbarBrand>
            <NavbarToggler onClick={toggle}>
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>

            <Collapse id="navbarCollapse" isOpen={state.isOpenMenu} navbar>
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="200"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="navbar-center" id="mySidenav">
                  {navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink href={"#" + item.idnm}>
                        {" "}
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <div className="nav-button ms-auto">
                  <Nav navbar className="navbar-end">
                    <li>
                      {/* Additional nav items or buttons can go here */}
                    </li>
                  </Nav>
                </div>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </nav>
      </div>
    </React.Fragment>
  );
};

export default NavbarPage;
